import React from "react";

const foerderer = [
  "images/partner/FördererAutorenstiftungLogo.jpg",
  "images/partner/FördererBürgerstiftung.jpg",
  "images/partner/FördererDeutscherLiteraturfonds.png",
  "images/partner/FördererFachbereich2.png",
  "images/partner/FördererFriedrichWeinhagenStiftung.jpg",
  "images/partner/FördererHildesheim.png",
  "images/partner/FördererNMWK.jpg",
  "images/partner/FördererProHelvetia.png",
  "images/partner/FördererSparkasse.png",
  "images/partner/FördererStiftungNiedersachsen.png",
  "images/partner/FördererStudierendenwerk.png",
  "images/partner/FördererUnipräsidium.jpg",
  "images/partner/FördererUniversitätsgesellschaft.jpg",
  "images/partner/FördererVGHS.png",
]

const koops = [
  "images/partner/FördererFachbereich2.png",
  "images/partner/KoopHoeme.png",
  "images/partner/KoopKET.png",
  "images/partner/KoopLitInst.png",
  "images/partner/KoopLitradio.jpeg",
  "images/partner/KoopLStJ.png",
  "images/partner/KoopNDR.jpg",
  "images/partner/KoopPuls.png",
  "images/partner/KoopUniHildesheim.jpg",
  "images/partner/KoopWallungen.png"
]


export const InfoBubble1Text = <>
  {/* PROSANOVA 2023 ist ein Festival für junge Literatur, das vom 23. -
    25. Juni 2023 in Hildesheim stattfindet. <br></br> <br></br>Seit 2005 findet
    das bundesweit größte und bekannteste Event für junge,
    deutschsprachige Gegenwartsliteratur im Rhythmus von drei Jahren in
    Hildesheim - mitten in Niedersachsen - statt. 40 junge
    Gegenwartsautor*innen sind eingeladen, ihr Schreiben, ihre Texte und
    literarischen Verfahren in Hildesheim zu inszenieren und in über 30
    Veranstaltungen einem breiten Publikum aus Fachbesucher*innen des
    Literaturbetriebs, Hildesheimer Bürger*innen, Studierenden sowie
    überregionalen Pressevertreter*innen vorzustellen. */}
  <p>
    Vom 23.-25. Juni 2023 geht das PROSANOVA in die siebte Runde. Seit 2005 findet dieses bundesweit größte und bekannteste Event für junge, deutschsprachige Gegenwartsliteratur im Rhythmus von drei Jahren in Hildesheim statt. Über 40 Gegenwartsautor:innen sind eingeladen, ihr Schreiben, ihre Texte und literarischen Verfahren in Hildesheim zu inszenieren, zu zelebrieren, anzuzweifeln, sichtbar und gleichsam auch erlebbar zu machen und in über 50 Veranstaltungen einem breiten Publikum aus Fachbesucher:innen des Literaturbetriebs, Hildesheimer Bürger:innen, Studierenden sowie überregionalen Pressevertreter:innen vorzustellen. Aber nicht nur das: Neben Lesungen wird es außerdem Party, Musik, Poetik, Performance, Installationen, Inszenierungen und alles, was dazwischen liegt, geben.
  </p>
  <p>
    Das PROSANOVA wird voraussichtlich in der ehemaligen Grundschule Hohnsen (Keßlerstr. 51, 31134 Hildesheim) stattfinden. Vom Hauptbahnhof Hildesheim kann man entweder ungefähr 25 Minuten laufen oder man fährt mit dem Bus. Dazu muss man entweder die Linie 1 (in Richtung Südfriedhof/Scharfe Ecke) oder die Linie 2 (in Richtung Im MIttelfeld) nehmen und am HAWK-Campus aussteigen. Alternativ kann man auch mit der Regionalbahn bis zum Ostbahnhof fahren und von dort ungefähr fünf Minuten laufen.
  </p>

  <br></br>
  <span className="font-b-bold">Schlechte Wörter</span><br></br>
  <p>
    Die diesjährige Ausgabe des PROSANOVAs findet unter dem Motto „Schlechte Wörter“ statt. Der titelgebende Kurzprosatext von Ilse Aichinger bildet für uns den Anlass, den Raum hinter den Worten und den Texten zu erforschen. Wir begeben uns auf die Suche nach einer zweiten Ebene der Worte, nach der Ambivalenz des Geschriebenen und nach dem, was wir überhaupt meinen, wenn wir Text sagen.
  </p>
  <p>
    Hoffnungsvoll schauen wir auf das, was uns erwartet, wenn wir mit der Suche beginnen: Gibt es missverstandene Literatur? Gibt es ein Danach der schlechten Wörter? Wenn Sprache Handlung ist - was macht das mit den etablierten Rezeptionsweisen von Text? Wie würde ein Versuch aussehen, den Literaturbetrieb zu dekonstruieren – bis in seine kleinsten Bestandteile? Was ist dieses Unbehagen, das dem Text vorausgesetzt zu sein scheint? Die Kritik, die uns interessiert, ist eine freundschaftliche, eine zugewandte, und vor allem eine, die auch den Blick nach innen wagen soll.
  </p>
  <p>
    Letztlich müssen wir uns damit anfreunden, dass auch das PROSANOVA nur eine von vielen möglichen Bestandsaufnahmen des deutschsprachigen Literaturbetriebs ist. Doch genau dieser Tatsache wollen wir uns stellen. Wenn wir anerkennen, dass auch wir nur ein Teil des Ganzen sind und letztlich jede:r seine eigene Wahrheit hat, müssen wir uns mit dem Scheitern anfreunden. Genau dieses wollen wir als Grundlage eines jeden Textes und jeden Umgangs mit ihm anerkennen: Dort, wo ein Wort steht, könnte auch jedes andere Wort stehen. Wir untersuchen Worte, die uns verraten und ausliefern, und begeben uns gemeinsam auf die Suche, um ins Gespräch und ins Schweigen zu kommen. Wir wollen ein neues Terrain für die Rezeption von Text erproben. Also: Was passiert, wenn wir Texte als Räume begreifen, die wir begehen und von allen Seiten betrachten können?
  </p>
</>

export const InfoBubble2Text = <>
  <p>KOOPERATIONS-<br />PARTNER</p>

  <div className='imprint-logo-grid'>
    {koops.map((filename) => {
      return <div style={{
        display: "flex",
        justifyItems: "center"
      }}>
        <img src={filename} style={{ width: "100%", alignSelf: "center" }} />
      </div>
    })}
  </div>
  <p>FÖRDERER</p>

  <div className='imprint-logo-grid'>
    {foerderer.map((filename) => {
      return <div style={{
        display: "flex",
        justifyContent: "center"
      }}>
        <img src={filename} style={{ width: "100%", alignSelf: "center" }} />
      </div>
    })}
  </div>
  {/* Die diesjährige Ausgabe des Prosanovas findet unter
    dem Motto Schlechte Wörter statt. Der titelgebende Kurzprosatext von
    Ilse Aichinger gibt uns den Anlass für eine Suche nach den Wörtern -
    überall. Wir hinterfragen Eindeutigkeit und suchen nach
    Mehrdeutigkeit, wir wollen ein neues Terrain für die Rezeption von
    Text erproben. Was passiert, wenn wir Texte als Räume begreifen, die
    wir begehen und von allen Seiten betrachten können? */}
</>