import { PrettyName } from "contents/Team/TeamComponents";
import React from "react";

export const LineUpInformation = [
  {
    title: "Xoşewîst",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Xosewist_Foto_privat.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Xoşewîst"} /> (ausgesprochen: Khoshewist) wurde
            1987 in Hasaka, Syrien, geboren. Sein amtlicher Name ist Abdulaziz
            Ramadan. Er studierte an den Universitäten Damaskus, Halle, Leipzig
            und Oxford. Xoşewîst ist Gründer und Geschäftsführer von DOZ e.V.
            (DOZ International) und lebt in Leipzig.Leipzigيّاt ist sein Debüt.
            Xoşewîst hat den Preis "Bayern Beste Independet Bücher 2020" für
            seinen Buch LeipzigيّاT bekommen.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Thomas Stangl",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Thomas_Stangl_Foto_privat.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Thomas"} /> <PrettyName name={"Stangl"} />,
            geboren 1966 in Wien, studierte Philosophie und Spanisch, lebt in
            Wien. Zuletzt erschienen der Roman Quecksilberlicht (Matthes &
            Seitz, Berlin 2022) und der Erzählband Diverse Wunder. Noch eine
            Handvoll sehr kurzer Geschichten (Droschl, Graz, 2023). Zahlreiche
            Auszeichnungen, zuletzt der Kunstpreis für Literatur der Republik
            Österreich 2022 und der Bremer Literaturpreis 2023.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Tatjana von der Beek",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/VonderBeek_Tatjana_Foto_JohannaBaschke.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Tatjana"} /> von der <PrettyName name={"Beek"} />
            , geboren 1993, lebt und arbeitet in Leipzig. Sie studierte
            Literarisches Schreiben und Lektorieren in Hildesheim, war
            Mitherausgeberin der BELLA triste, Teil der künstlerischen Leitung
            des PROSANOVA 2017 und Finalistin des 23. Open Mike. Sie hat bereits
            in mehreren Zeitschriften und Anthologien veröffentlicht. "Die Welt
            vor den Fenstern" ist ihr Debütroman. <br></br>
            <br></br> Foto: Johanna Baschke
          </p>
        </div>
      </>
    ),
  },

  {
    title: "stolzeaugen.books",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Logo-Stolze-Augen.png"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"stolzeaugen.books"} /> ist die erste BIPoC
            Verlagsgesellschaft bundesweit. Der Verlag wurde am 08.05.2020 mit
            dem Ziel gegründet, zur stärkeren Sichtbarkeit der Perspektiven von
            BIPoC in Deutschland beizutragen, um ein Leben in Augenhöhe
            voranzutreiben. Am 19. Februar 2021 veröffentlichte der Verlag
            "Texte nach Hanau" - ein Sammelband mit 63 Texten von 50 BIPoC
            Autor:innen und einem Vorwort von Serpil Unvar. „Texte nach Hanau“
            bildet die Stimmen von Menschen ab, die ihre Gefühle und Gedanken,
            Enttäuschungen und Erwartungen nach dem Anschlag in Hanau am 19.
            Februar 2020 zum Ausdruck bringen. <br></br>
            <br></br>
            <img
              alt=""
              style={{ width: "100%", borderRadius: "2.2rem" }}
              src="/images/Crooks_Michelle.jpeg"
            ></img>
            <br></br> <br></br>
            <PrettyName name={"Michelle"} /> <PrettyName name={"Crooks"} />, 27,
            B.A. Lehramt mit den Fächern Deutsch und Philosophie, derzeit im
            Masterstudium Interkulturelle Kommunikation und Bildung in Köln. Sie
            ist im Orga-Team des Autonomen BIPoC Referats der Universität zu
            Köln tätig und Teil der Arbeitskreise Antidiskriminierung und
            Veranstaltungen. Nebenbei gibt sie Antirassismus-Workshops für
            Jugendliche an Schulen. Seit Januar 2022 ist sie bei
            stolzeaugen.books zuständig für Lektorat und assistierende Aufgaben.
            <br></br>
            <br></br>
            <img
              alt=""
              style={{ width: "100%", borderRadius: "2.2rem" }}
              src="/images/Aghedo_Jennifer_Foto_ privat.jpg"
            ></img>
            <br></br> <br></br>
            <PrettyName name={"Jennifer"} /> <PrettyName name={"Aghedo"} />
            studierte Kulturwirtschaft an der Universität. Aufgrund ihrer
            Leidenschaft für Literatur und der kreativen Auseinandersetzung mit
            feministischen, antirassistischen und weiteren politischen Themen
            fing sie an, sich ehrenamtlich im Bereich Social Media bei der BIPoC
            Verlagsgesellschaft stolzeaugen.books zu engagieren. Neben ihrer
            hauptberuflichen Tätigkeit unterstützt sie die Produktion von
            Kulturveranstaltungen mit einem antirassistischen Fokus. Mittels
            eigener Texte setzt sie sich mit ihrer Lebensrealität als Schwarze
            deutsche Frau auseinander, um diese einem weiteren Publikum näher zu
            bringen.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Stoff aus Luft",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <p className="font-size-1">
            <PrettyName name={"Stoff"} /> <PrettyName name={"aus"} />{" "}
            <PrettyName name={"Luft"} />
            ist ein auditives Literaturmagazin im Podcastformat mit Fokus auf
            Spoken Word und Soundpoetry. Diese Literaturformen finden in den
            wichtigen deutschen Literaturzeitschriften bisher keine adäquate
            Publikationsmöglichkeit. Stoff aus Luft ist deshalb eine
            Literaturzeitschrift zum Anhören. Unter je Folge wechselnden,
            offenen Themen versammelt das Magazin neue Texte aus auditiven
            Literaturformen, vorgetragen von den Autor:innen sowie kurze
            Interviews: was sind Texte abseits der Schriftlichkeit? Wie sind sie
            gebaut, wie hält man sie fest, und was spricht? Stoff aus Luft wird
            auf allen gängigen Podcastplattformen und auf www.stoffausluft.de
            ausgestrahlt und erscheint alle zwei Monate. Zum ersten Mal am
            16.02.2022. Tanasgol Sabbagh und Josefine Berkholz sind seit über
            zehn Jahren in den verschiedenen Literaturszenen zwischen Papier und
            Bühne zuhause. Ihre Tätigkeit als Dichterinnen hat sie u.A. ans
            Deutsche Literaturinstitut, auf Theater- und Konzerthausbühnen, in
            Kneipenkeller und in Kollaborationen mit Musiker:innen und
            Filmemacher:innen geführt. Die Gründung und Gestaltung eines
            auditiven Literaturmagazins steht vor dem Hintergrund dieser
            langjährigen Erfahrung mit und in performativen und gesprochenen
            Literaturformen.
          </p>

          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/SaL_Sabbagh_Fotograf © Sergen Yener.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Tanasgol"} />
            <PrettyName name={"Sabbagh"} />
            ist Dichterin und Spoken Word Autorin. Sie ist in Amol, Iran,
            geboren und in Hessen aufgewachsen. Ihre literarischen Arbeiten
            präsentiert sie in Performances, Audiostücken, Videoinstallationen
            und musikalischen Kollaborationen. Sie ist Mitbegründerin des
            Künstler:innenkollektivs parallelgesellschaft sowie der
            gleichnamigen Veranstaltungsreihe, die politische Kunst abseits der
            deutschen Leitkultur verhandelt. Zusammen mit der Lyrikerin Josefine
            Berkholz ist sie Gründerin und Redakteurin des auditiven
            Literaturmagazins Stoff aus Luft, das als Podcast erscheint: Ein
            Format das gesprochene und klangbasierte Literatur in den
            Vordergrund stellt. Tanasgol lebt in Berlin.
          </p>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/SaL_Berkholz_Fotograf©Ken Yamamoto.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Josefine"} />
            <PrettyName name={"Berkholz"} />
            wurde 1994 geboren und lebt in Berlin. Sie hat Literarisches
            Schreiben am Deutschen Literaturinstitut Leipzig und Philosophie und
            Sozialwissenschaften an der Humboldt Universität Berlin studiert und
            schreibt Lyrik, Essays und Performatives. In ihrer Arbeit
            interessiert sie sich besonders für die Übergänge zwischen
            literarischen Gattungen, sowie zwischen wissenschaftlichem und
            poetischem Denken. Sie tritt seit 2010 als Performerin und
            Spokenwordautorin auf und arbeitet am liebsten in Kollaboration,
            auch und besonders mit Künstler:innen anderer Sparten.
          </p>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/IMG_6471-1.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Fabian"} />
            <PrettyName name={"Saul"} />, geboren 1986, ist Autor, Komponist und
            seit 2013 Chefredakteur des Magazins Flaneur. Das Heft, das sich in
            jeder Ausgabe einer anderen Straße in der Welt widmet, verfolgt
            einen interdisziplinären Ansatz, der sich auch in Sauls Arbeit
            wiederfindet. Er studierte Kulturwissenschaft und Philosophie. Neben
            seiner schriftstellerischen und redaktionellen Arbeit arbeitet er
            seit vielen Jahren als Komponist für Filmmusik und Songwriter. Er
            lebt in Berlin, seine Arbeit führt ihn jedoch regelmäßig an andere
            Orte.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "சிந்துஜன் வரதராஜா (Sinthujan Varatharajah)",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Varatharajah_Sinthujan_Foto_ Florinel Fratica.png"
          ></img>
          <p className="font-size-1">
            சிந்துஜன் வரதராஜா (Sinthujan Varatharajah) lebt als politische
            Geograf:in und Essayist:in in Berlin. Varatharajahs Arbeiten
            befassen sich mit der Staatenlosigkeit und Vertreibung aus einer
            räumlichen, logistischen und materialistischen Perspektive.
            Varatharajahs erstes Buch, 'an alle orte, die hinter uns liegen',
            ist im Herbst 2022 im Hanser Verlag erschienen. Das Buchprojekt
            'Englisch in Berlin', eine Kollaboration mit Moshtari Hilal, ist im
            gleichen Jahr bei Wirklichkeits Books erschienen.
            <br></br>
            <br></br> Foto: Florinel Fratica
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Simoné Goldschmidt-Lechner (sgl)",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Simoné Goldschmidt-Lechner_c Maik Gräf.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Simoné"} />
            <PrettyName name={"Goldschmidt-"} appendWhiteSpace={false} />
            <PrettyName name={"Lechner"} />
            (sgl) schreibt Prosa, Lyrik und Drama, übersetzt, macht Podcasts,
            beschäftigt sich mit (queeren) Fankulturen im Netz, Horror aus
            postmigrantischer Perspektive und Sprache in Videospielen. Sie ist
            Autor:in des Romans _Messer, Zungen_ (2022) und (Mit-)Übersetzerin
            von u.a. _Against White Feminism _(2022), _Exponiert_ (2023), _Die
            Patriarchen_ (2023), _Unertrunken_ (2022) und der Autobiographie von
            Angela Y. Davis (2023). Literatur- und Kulturvermittlung seit 2019,
            starting with PROSANOVA 2020 und dem Online-Release-Festival für
            FUTUR 3/No hard Feelings (2021).
            <br></br>
            <br></br> Foto: Maik Gräf
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Selma Kay Matter",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/89846F18-5A9B-4975-A18C-1FF497D0DEE3.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Selma"} />
            <PrettyName name={"Kay"} />
            <PrettyName name={"Matter"} appendWhiteSpace={false} />, geboren in
            Zürich, ist Autor:in und Dramaturg:in und studiert Szenisches
            Schreiben an der Universität der Künste Berlin. Zuletzt hat dey im
            Duo mit Marie Lucienne Verse das Stück Alias Anastasius geschrieben,
            das gerade am Berliner Ensemble zu sehen ist. Selma Matters Stück
            Grelle Tage hatte 2023 am Schauspielhaus Wien Uraufführung und wurde
            u.a. mit dem Hans-Gratzer-Preis 2022 ausgezeichnet. Im September
            erscheint es als Buch im Suhrkamp Verlag. Selma Matter war
            Mitherausgeber:in der BELLA triste und Teil der künstlerischen
            Leitung von PROSANOVA 2020. Derzeit schreibt dey an einem
            autofiktionalen Text zu den Schnittstellen von chronischer
            Erkrankung und Queerness.
            <br></br>
            <br></br> Foto: Max Zerrahn
          </p>
        </div>
      </>
    ),
  },
  {
    title: "SCHLECHTE WÖRTER / BAD WORDS",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/SchlechteWorter_16x9.png"
          ></img>
          <p className="font-size-1">
            Ausgehend von Aichingers Text, schafft die Audioserie Schlechte
            Wörter / Bad Words einen Ort für ein anderes Sprechen über Sprache
            und Literatur, für die Annäherung an ein neues Sprachgefühl. Aus
            Gesprächen, Lesungen, Sprachnachrichten, Field Recordings und Musik
            entsteht ein begehbarer, vielstimmiger Raum mit wechselnden Gästen.
            Die Verabredung lautet: Wir gehen von einem Text aus, damit ein
            anderer Text beginnen kann.
          </p>
          <p className="font-size-1">
            Eine Audioserie von Fabian Saul, in Zusammenarbeit mit Mathias
            Zeiske. Mit Beiträgen von Don Mee Choi, Sophia Eisenhut, Lama El
            Khatib, Haytham El Wardany, Merve Emre, Joshua Groß, Hanne Lippard,
            Enis Maci, Nalan, Thuy-Han Nguyen-Chi, Tanasgol Sabbagh, Miriam
            Stoney, Karosh Taha, Senthuran Varatharajah, Uljana Wolf sowie
            Texten von Ilse Aichinger und Etel Adnan.
          </p>
          <br></br>
          <p className="font-size-1">Fabian Saul</p>
          <p className="font-size-1">
            Fabian Saul ist Autor, Komponist und seit 2013 Chefredakteur des
            Magazins Flaneur. Das Heft, das sich in jeder Ausgabe einer anderen
            Straße in der Welt widmet, verfolgt einen nomadischen und
            interdisziplinären Ansatz, der sich auch in Sauls Arbeit
            wiederfindet. Neben seinen Tätigkeiten als Schriftsteller und
            Redakteur arbeitet er seit vielen Jahren als Komponist für Filmmusik
            und Songwriter. Er lebt in Berlin, seine Arbeit führt ihn jedoch
            regelmäßig an andere Orte.
          </p>
          <br></br>
          <p className="font-size-1">Don Mee Choi</p>
          <p className="font-size-1">
            Don Mee Choi ist Autorin des mit dem National Book Award
            ausgezeichneten Buches DMZ Colony (2020). Sie ist 2021 Fellow der
            MacArthur Foundation und der John Simon Guggenheim Foundation. 2019
            war sie Stipendiatin des DAAD Künstlerprogramms in Berlin und ist
            derzeit als Picador-Gastprofessorin in Leipzig.
          </p>
          <br></br>
          <p className="font-size-1">Lama El Khatib</p>
          <p className="font-size-1">
            Lama El Khatib ist ausgebildete Architektin und studierte
            Kunstgeschichte und Philosophie an der American University of
            Beirut. Derzeit absolviert sie einen Masterstudiengang in
            Philosophie an der Freien Universität Berlin. Seit 2018 arbeitet sie
            im Rahmen von The Whole Life Academy und The Whole Life. An Archive
            Project am Haus der Kulturen der Welt in Berlin.
          </p>
          <br></br>
          <p className="font-size-1">Merve Emre</p>
          <p className="font-size-1">
            Merve Emre ist Associate Professor für Englisch an der University of
            Oxford und Autorin für The New Yorker. Von 2020-21 war sie Fellow am
            Wissenschaftskolleg zu Berlin.
          </p>
          <br></br>
          <p className="font-size-1">Hanne Lippard</p>
          <p className="font-size-1">
            Hanne Lippard lebt und arbeitet in Berlin. In Kurzfilmen,
            Installationen, Sound- und Performance-Arbeiten erforscht Lippard
            die Stimme als Gestaltungsmittel. Inspiriert durch ihre Ausbildung
            als Grafikdesignerin zeigt sie, wie Sprache visuell kraftvoll sein
            kann. Ihre Texte sind bildlich, rhythmisch und performativ, nicht
            bloß rein informativ. Ihre letzten Performances und Ausstellungen
            wurden präsentiert bei RIBOCA2, Riga (2020), ART 4 ALL, Hamburger
            Bahnhof Berlin (2020), Our present, Museum für Gegenwartskunst
            Siegen (2020), Le language est une peau, FRAC Lorraine, Metz (2021),
            Contact, Mood, Share, MHKA, Antwerp (2021).
          </p>
          <br></br>
          <p className="font-size-1">Tanasgol Sabbagh</p>
          <p className="font-size-1">
            Tanasgol Sabbagh schreibt Lyrik und Spoken Word Texte. Seit über
            zehn Jahren performt sie als freischaffende Bühnenpoetin auf
            vielzähligen Bühnen im deutschsprachigen Raum, ihre Texte sind in
            verschiedenen Bühnen- und Videoformaten inszeniert worden. Sie ist
            Gründungsmitglied des Künstler*innenkollektivs parallelgesellschaft
            und der gleichnamigen Veranstaltungsreihe in Berlin. Sabbagh setzt
            sich für literarische Nachwuchsförderung ein und leitet
            Schreibworkshops für Jugendliche und Schüler*innen.
          </p>
          <br></br>
          <p className="font-size-1">Miriam Stoney</p>
          <p className="font-size-1">
            Miriam Stoney lebt als Autorin und Übersetzerin in Wien. Ihre meist
            kollaborative Praxis ist textbasiert, umfasst aber auch
            künstlerisches Schreiben, Performance, Audio und Installation. 2022
            erscheint ihr Roman bei Broken Dimanche Press. 2021 hatte sie im
            Kunstverein Kevin Space in Wien ihre erste Einzelausstellung,
            außerdem wurden ihre Arbeiten unter anderem im Neuen Kunstverein
            Wien und BBC Introducing Arts gezeigt.
          </p>
          <br></br>
          <p className="font-size-1">Karosh Taha</p>
          <p className="font-size-1">
            Karosh Taha lebt und schreibt im Ruhrgebiet. 2018 erschien ihr
            Debütroman Beschreibung einer Krabbenwanderung, im April 2020 folgte
            Im Bauch der Königin. Für ihre Werke erhielt sie mehrere Preise und
            Stipendien, darunter den Hohenemser Literaturpreis 2019 und das
            Werkstipendium des Deutschen Literaturfonds.
          </p>
          <br></br>
          <p className="font-size-1">Senthuran Varatharajah</p>
          <p className="font-size-1">
            Senthuran Varatharajah ist Schriftsteller und Philosoph. Er
            studierte Philosophie, evangelische Theologie und vergleichende
            Religions- und Kulturwissenschaft in Marburg, Berlin und London.
            2016 erschien sein mehrfach ausgezeichneter Debütroman Vor der
            Zunahme der Zeichen; Varatharajahs zweiter Roman Rot (Hunger)
            erscheint im März 2022. Seine Texte handeln vom Verhältnis von
            Biographie, Poetologie, Philosophie und Theologie, unter den
            Bedingungen von Flucht, Asyl, Exil und Genozid.
          </p>
          <br></br>
          <p className="font-size-1">Uljana Wolf</p>
          <p className="font-size-1">
            Uljana Wolf ist Lyrikerin und Übersetzerin. Sie veröffentlichte vier
            Gedichtbände und zahlreiche Lyrikübersetzungen u. a. aus dem
            Polnischen und Belarussischen. 2021 erschien Etymologischer Gossip.
            Essays und Reden. Mit Marie Luise Knott kuratierte sie die
            Ausstellung Die Hochsee der Ilse Aichinger. Ein unglaubwürdiger
            Reiseführer zum 100. Geburtstag im Literarischen Colloquium Berlin.
            Wolfs Werk wurde u. a. mit dem Adelbert-von-Chamisso-Preis 2016 und
            dem Kunstpreis Berlin 2019 ausgezeichnet.
          </p>
          <p className="font-size-1"></p>
        </div>
      </>
    ),
  },
  {
    title: "Saskia Vogel",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Vogel_Saskia_Foto_ Nikolaus Kim.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Saskia"} />
            <PrettyName name={"Vogel"} />
            ist eine mehrfach ausgezeichnete Schriftstellerin und Übersetzerin
            aus Los Angeles und lebt in Berlin. Ihr Debütroman „Permission“
            wurde in fünf Sprachen veröffentlicht. Ihre Werke wurden von der
            Stiftung des Berliner Senats für nicht deutschsprachige Literatur
            ausgezeichnet und standen auf der Longlist für den Believer Book
            Award sowie den Pushcart Prize. Im Herbst 2022 war sie Übersetzerin
            in Residence an der Princeton University. Ihre Texte kann man unter
            anderem im *New Yorker*, in der *New York Times* und im *Granta
            Magazine* lesen.
            <br></br>
            <br></br> Foto: Nikolaus Kim
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Sarah Elena Müller",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Sarah_Elena_Mueller_Press_Laura_Stevens_7.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Sarah"} />
            <PrettyName name={"Elena"} />
            <PrettyName name={"Müller"} />
            *1990 arbeitet multimedial in Literatur, Musik, Virtual Reality,
            Hörspiel und Performance. Ihr Interesse gilt allen Formen von Text
            und Sprachlichkeit, technologischen und sozialen Entwicklungen,
            sowie tanzbarer Musik. Sie tritt im Spoken Pop Duo «Cruise Ship
            Misery» mit der Sängerin Milena Krstic als Ghostwriterin und
            Musikerin auf, fungiert als Beatmakerin für die kongolesische
            Rapperin Orakle Ngoy und ist Mitbegründerin des feministischen
            Autor:innenkollektivs RAUF. 2015 erschien die Erzählung «Fucking
            God» beim Verlag Büro für Problem. Seit 2019 leitet sie das Virtual
            Reality Projekt «Meine Sprache und ich» – eine Annäherung an Ilse
            Aichingers Sprachkritik. 2021 erschien der Szenenband «Culturestress
            - Endziit isch immer scho inbegriffe» beim Verlag der gesunde
            Menschenversand. 2023 erschien ihr Debüt Roman «Bild ohne Mädchen»
            beim Limmat Verlag.
            <br></br>
            <br></br> Foto: Laura Stevens
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Sakine Cansız (Sara)",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Sara für Lit.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Sakine"} />
            <PrettyName name={"Cansız"} />
            <br></br> Hevala Sara kam 1958 in Dersim (Tunceli) als Kind einer
            kurdisch-alevitischen Familie zur Welt. Diese beiden in der Republik
            Türkei diskriminierten Identitätsmerkmale verteidigte sie immer und
            unter allen Umständen. Auch deshalb schloss sie sich der PKK an. Sie
            nahm am PKK-Gründungskongress im Jahr 1978 teil und gehörte zu den
            fünf noch lebenden Gründungsmitgliedern. In Elazîz (Elazığ) und
            Çewlik (Bingöl) beteiligte sie sich an der Organisierungsarbeit der
            PKK. Nach dem Militärputsch am 12. September 1980 wurde sie
            verhaftet und verbrachte viele Jahre im berüchtigten Gefängnis von
            Amed (Diyarbakır), wo sie dem für die unmenschliche Folter
            verantwortlichen Militärkommandanten Esat Oktay Yıldıran ins Gesicht
            spuckte und durch ihren Widerstand zur lebenden Legende wurde. Sie
            war auch die erste Frau im kurdischen Befreiungskampf, die eine
            politische Verteidigung vor Gericht vorlegte. Nach zwölf Jahren Haft
            setzte sie ihren Kampf in verschiedenen Bereichen fort. 1995 nahm
            sie am ersten kurdischen Frauenkongress teil, der Grundlage für die
            Bildung einer Frauenpartei war. Sie war auch dabei, als die ersten
            Frauen-Guerillaeinheiten gebildet wurden. In ihrem über
            dreißigjährigem Kampf war sie Guerillakommandantin,
            Frauenrechtlerin, Lehrerin im Flüchtlingslager Maxmur, Führungskraft
            der PKK, Lehrende an Akademien und eine Diplomatin, die sich dafür
            einsetzte, den kurdischen Befreiungskampf auf der ganzen Welt
            bekannt zu machen. Aber alle, die sie kannten, definierten sie vor
            allem als eine Genossin und Weggefährtin. Gleichzeitig war sie das
            lebende Gedächtnis des kurdischen und Frauen-Befreiungskampfes. Sie
            wurde am 9. Januar 2013 gemeinsam mit ihren Genossinnen Fidan Doğan
            (Rojbîn) und Leyla Şaylemez (Ronahî) im Informationszentrum
            Kurdistan in Paris vom türkischen Geheimdienst MİT ermordet.
          </p>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/EstherWinckelmann.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Esther"} />
            <PrettyName name={"Winckelmann"} />, Antiquarin, politische
            Aktivistin, aktives Mitglied bei Azadî e.V. Rechtshilfefonds.
          </p>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/AnjaFlach.png"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Anja"} />
            <PrettyName name={"Flach"} />, Ethnologin und Aktivistin der
            kurdischen Frauenbewegung, Autorin verschiedener Bücher zur
            kurdischen Frauenbewegung. Mitübersetzerin von Sakine Cansız „Mein
            ganzes Leben war ein Kampf“.
          </p>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/LeylaKaya.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Leyla"} />
            <PrettyName name={"Kaya"} />
            ist Krankenschwester und Mutter 1 Tochter. Sie ist Gründungsmitglied
            des 1. Kurdischen Frauenvereins in Hamburg im Jahr 2000 und 2005
            Gründungsmitglied des 1. Frauenrates in Hamburg. Seitdem ist sie im
            Frauenrat in Rojbin aktiv.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "rio&dio productions™",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="images\InaDiallo (rio&dio).jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"INA"} /> <PrettyName name={"DIALLO"} /> (sie/Ina)
            studiert Kulturwissenschaften und ästhetische Praxis an der
            Universität Hildesheim. Zuvor war Ina als FSJ-ler:in und
            Regieassistent:in am Jungen Schauspiel Haus Hamburg tätig. Seit 2019
            ist Ina Teil der Performance-Gruppe elenapatrowna und seit 2021 Teil
            von dem Technik-Kollektiv rio&dio productions™. Ina arbeitet in
            unterschiedlichen Kontexten als freie Techniker:in und Videograf:in
          </p>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Rio_c_Lisa Mausbach.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Rio"} />{" "}
            <PrettyName name={"Theis"} appendWhiteSpace={false} />,<br></br>{" "}
            *1998, studiert Inszenierung der Künste und der Medien in
            Hildesheim. Zuvor studierte they Kulturwissenschaften und
            ästhetische Praxis. Rio war zuletzt an verschiedenen
            Performance-Produktionen beteiligt, darunter{" "}
            <span className="font-montiac-italic">FRAGMENTEZ</span> und{" "}
            <span className="font-montiac-italic">bbb</span>. They ist Teil des
            Performance- und Technik-Kollektivs{" "}
            <span className="font-montiac-italic">rio&dio productions</span>™
            und arbeitet freischaffend als Lichttechniker:in und
            Produktionsassistenz u.a. für die Frl. Wunder AG und das
            Schauspielhaus Hannover. Aktuell organisiert Rio verschiedene
            Veranstaltungen, um Drag in Hildesheim zu etablieren.
            <br></br>
            <br></br> Foto: Lisa Mausbach
          </p>
        </div>
      </>
    ),
  },

  {
    title: "poco.lit",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Poco.lit_ von Rath_Anna, Gasser_Lucy, Foto_ Michelle Rue.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Anna"} /> <PrettyName name={"von"} />
            <PrettyName name={"Rath"} /> und <PrettyName name={"Lucy"} />{" "}
            <PrettyName name={"Gasser"} /> sind Literaturwissenschaftlerinnen
            mit Schwerpunkt auf Postkolonialismus. Gemeinsam haben sie poco.lit.
            <a href="https://pocolit.com/">https://pocolit.com/</a>, ein
            bilinguales Online-Magazin für postkoloniale Literatur, und
            macht.sprache.
            <a href="https://www.machtsprache.de/">
              https://www.machtsprache.de/
            </a>
            , eine Plattform für politisch sensibles Übersetzen, gegründet.
            <br></br>
            <br></br> Foto: Michelle Rue
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Olufemi-Just Atibioke",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Atibioke_Olufemi_Privat.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Olufemi-Just"} />{" "}
            <PrettyName name={"Atibioke"} />
            ist studierter Kulturwissenschaftler. Arbeitet in der Kulturellen
            Bildung. Schreibt Text. Letzte Veröffentlichungen: Story des Jares
            in BELLA triste Nr. 63.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Odile Kennel",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Kennel_Odile_Foto_ Charlotte_Werndt.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Odile"} /> <PrettyName name={"Kennel"} />
            lebt in Berlin als Lyrikerin und Übersetzerin vorwiegend
            zeitgenössischer Lyrik aus dem Französischen, Portugiesischen,
            Spanischen, Englischen. Sie schreibt auf Deutsch und Französisch und
            lädt gerne weitere Sprachen in ihre Texte ein. Zuletzt erschien der
            Gedichtband Hors Texte (2019) sowie das poetische Essai Lust (2021).
            In Übersetzung u.a. Douglas Diegues, Ricardo Domeneck, Angélica
            Freitas, Margarida Vale de Gato, Luiza Neto Jorge, Jacques Darras,
            Robin Coste Lewis. 2022 erhielt sie den Paul-Scheerbart-Preis der
            Rowohlt-Ledig-Stiftung für ihre Lyrikübersetzungen sowie eine
            Gastdozentur des DÜF. Ist gelegentlich als Bloggerin unterwegs
            https://odilekennel.blogspot.com/ und im virtuellen Universum unter
            @solidenelke zu finden.
            <br></br>
            <br></br>
            Foto: Charlotte Werndt
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Natasha A. Kelly",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/NatashaKelly.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Natasha"} /> <PrettyName name={"A."} />{" "}
            <PrettyName name={"Kelly"} />
            ist promovierte Kommunikationssoziologin, Autorin und Künstlerin.
            Mit ihrer preisgekrönten und international gereisten Dokumentation
            „Millis Erwachen“ feierte sie ihr Filmdebüt auf der 10. Berlin
            Biennale 2018. Ihr Regiedebüt beging sie 2019 mit der
            internationalen Aufführung ihrer Dissertationsschrift „Afrokultur“
            in drei Ländern und drei Sprachen. 2021 veröffentliche sie zwei
            weitere Bücher: „Rassismus, Strukturelle Probleme brauchen
            strukturelle Lösungen“ im Atrium Verlag und „Sisters and Souls“ im
            Orlanda Verlag. Foto: Samia Rachel
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Mirjam Wittig",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Mirjam Wittig, Foto von Stefan Klüter.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Mirjam"} /> <PrettyName name={"Wittig"} />
            hat Philosophie, Kulturwissenschaften und Literarisches Schreiben
            studiert und in Hildesheim als Mitherausgeberin der
            Literaturzeitschrift BELLA.triste und Teil der Künstlerischen
            Leitung des PROSANOVA-Festivals gearbeitet. Seitdem gelegentlich
            Lehraufträge in Literarischem Schreiben. Im Frühjahr 2022 erschien
            ihr Debütroman An der Grasnarbe im Suhrkamp Verlag. Aktuell arbeitet
            sie an ein paar Gedichten weiter, mit denen sie zum Leonce und Lena
            Wettbewerb 2023 eingeladen war, sowie am zweiten Romanprojekt, für
            das sie das Berliner Senatsstipendium für Literatur erhielt. Sie
            lebt in Berlin.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Melinda Nadj Abonji",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Foto_Melinda Nadj Abonji_©AyseYavas_2018.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Melinda"} /> <PrettyName name={"Nadj"} />{" "}
            <PrettyName name={"Abonji"} appendWhiteSpace={false} />, geboren am
            22. Juni 1968 in Bečej (heutiges Serbien). Schriftstellerin,
            Musikerin, Vorstandsmitglied von TESORO.
            <br></br>
            <br></br>
            Studium der Germanistik und Geschichte in Zürich (Lic. Phil. I).
            Seit 1998 Zusammenarbeit mit dem Lyriker und Sänger Jurczok 1001.
            Seit 2010 Zusammenarbeit mit dem Multiinstrumentalisten Balts Nill.
            November 2018: Poetikvorlesung am Literaturhaus in Zürich.
            <br></br>
            <br></br>
            Romane: Im Schaufenster im Frühling. Ammann, 2004. Tauben fliegen
            auf. Jung und Jung, 2010. Schildkrötensoldat. Suhrkamp, 2017.
            <br></br>
            <br></br>
            Preise: 2010: Deutscher und Schweizer Buchpreis (für Tauben fliegen
            auf). 2017: ZKB Schillerpreis (für Schildkrötensoldat). 2022: Erich
            Fried Preis.
            <br></br>
            <br></br>
            Foto: Ayse Yavas
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Martyna Murphy Baginski",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Martyna Murphy Baginski.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Martyna"} /> <PrettyName name={"Murphy"} />{" "}
            <PrettyName name={"Baginski"} />
            (*1995) studierte Philosophie-Künste-Medien und seit 2019
            Kulturwissenschaften und ästhetische Praxis mit Hauptfach Musik an
            der Universität Hildesheim. Murphy war auf Performance- und
            Theaterfestivals zu sehen, experimentiert mit elektronischen,
            Gedanken und akustischen Sounds solo, sowie mit anderen
            Künstler:innen und Band und produziert unter anderem Filmmusik. In
            ihrem künstlerischen Denken, welches unter anderem auch in Malerei
            realisiert wird, setzt sie sich mit Identitätsfragen, wie
            Queerfeminismus, eigener osteuropäischen Herkunft und den
            gesellschaftlichen Strukturen auseinander.
            <br></br>
            <br></br>
            Foto: Selbstportrait
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Karosh Taha",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Karosh_Taha.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Karosh"} /> <PrettyName name={"Taha"} /> 1987
            geboren und aufgewachsen in der kurdischen Stadt Zaxo, hat Anglistik
            und Geschichtswissenschaften an der Universität Duisburg-Essen
            studiert. Ihr Debütroman erschien unter dem Titel Beschreibung einer
            Krabbenwanderung im März 2018 beim DuMont Buchverlag. Im April 2020
            erschien ihr zweiter Roman Im Bauch der Königin als Wendebuch
            ebenfalls bei DuMont. Beide Romane erhielten zahlreiche Stipendien
            und Preise, zuletzt die Alfred-Döblin Medaille. Sie lebt und
            arbeitet in Köln und Paris.
            <br></br>
            <br></br>
            Foto: Havin Al-Sindy
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Julia Friese",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Friese_Julia_Foto_@_Info_folgt.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Julia"} /> <PrettyName name={"Friese"} /> ,
            geboren 1985 in Hagen, studierte in Berlin, Bochum und Honolulu.
            Seit 2011 veröffentlicht sie als Kulturjournalistin. Sie schreibt
            Kolumnen, Essays und Kurzgeschichten.2022 erschien ihr literarisches
            Debüt „MTTR“ im Wallstein Verlag, das 2023 für den Clemens-
            Brentano-Preis nominiert wurde. Sie lebt in Berlin.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Julia Buchberger",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/JuliaBuchberger.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Julia"} /> <PrettyName name={"Buchberger"} /> ist
            Dramaturgin und Theatermacherin. Sie studierte Szenische Künste an
            der Universität Hildesheim. Dort war sie an verschiedenen freien
            Theaterprojekten beteiligt, unter anderem an I wish I was a .jpg
            (Luise März, 2020) und r/u/with/me/still
            (Reiniger/Buchberger&Friends, 2021) und leitete die Festivals
            transeuropa fluid — Europäisches Festival für Performative Künste
            (2018) und State of the Art (2019). Ihre Solo-Performance 100 ways
            to imitate a chicken (2019) wurde u.a. ans Staatstheater
            Braunschweig, ans Theater Konstanz und zum Körber Studio Junge Regie
            eingeladen. Gemeinsam mit Patrick Kohn und Max Reiniger bildet sie
            die Arbeitsgruppe für institutionsästhetische Forschung und Praxis,
            aus deren gemeinsamer Arbeit 2021 der Sammelband Radikale
            Wirklichkeiten - Festivalarbeit als performatives Handeln erschien.
            Seit der Spielzeit 2021/22 arbeitet sie in der Dramaturgie am
            Theater Osnabrück, wo sie unter anderem das Festival reality check -
            Festival zum Theater der Gegenwart kuratierte und leitete.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Jen Calleja",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Calleja_Jen_Foto_ Robin_Christian.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Jen"} /> <PrettyName name={"Calleja"} /> ist die
            Autorin von VEHICLE: A VERSE NOVEL (Prototype, 2023), I'M AFRAID
            THAT'S ALL WE'VE GOT TIME FOR (Prototype, 2020), GOBLINS (Rough
            Trade Books, 2020), HAMBURGER IN THE ARCHIVE (if a leaf falls, 2019)
            und SERIOUS JUSTICE (Test Centre, 2016). Mit ihrer Übersetzung von
            Marion Poschmanns THE PINE ISLANDS stand sie auf der Shortlist für
            den Man Booker International Prize 2019 und war die erste
            Stipendiatin des Translator-in-Residence Programms an der British
            Library. Jen ist Mitherausgeberin bei PRASPAR PRESS, wo
            zeitgenössische maltesische Literatur in englischer Sprache und in
            englischer Übersetzung veröffentlicht wird. Sie lebt in Hastings,
            UK.
            <br></br>
            <br></br>
            Foto: Robin Christian
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Jehona Kicaj",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Kicaj_Foto.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Jehona"} /> <PrettyName name={"Kicaj"} /> , 1991
            in Suhareka, Kosovo geboren, studierte Philosophie, Germanistik und
            Neuere Deutsche Literaturwissenschaft in Hannover. Aktuell arbeitet
            sie als Lektorin für einen internationalen Wissenschaftsverlag und
            ist zudem Mitherausgeberin der literarischen Zeitschrift
            Echo&Narziss. Zuletzt veröffentlichte sie in der
            Literaturzeitschrift die horen die Texte Schatten der Diaspora
            (2020) und I also was a refugee (2021). Sie erhielt 2022 das
            SchreibZeit-Stipendium der Stiftung Niedersachsen und schreibt
            derzeit an ihrem ersten Roman mit dem Titel ë. In Prosa-Fragmenten
            verhandelt sie darin Sprachlosigkeit als Folge des Kosovo-Krieges.
            <br></br>
            <br></br>
            Foto: © Carl Philipp Roth.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Inana Othman",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/InanaOthman_Bild privat.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Inana"} /> <PrettyName name={"Othman"} /> ist
            Autorin, Forscherin und Übersetzerin. An der Schnittstelle des
            Politisch-Geschichtlichen, Imaginären und Lyrisch-Körperlichen
            schreibt und übersetzt sie multi-, wie zwischensprachlich, -sinnlich
            und -medial. Über die letzten zwei Jahre war sie Teil des
            Schreiblabors „Vergangenheit vorhersagen“ am Düsseldorfer
            Schauspielhaus. In dem unter demselben Titel im Juni 2022
            herausgebrachten Magazin erschienen lyrische Texte aus ihrem Projekt
            »Vorwärts Erinnern«. Ihre Arbeiten wurden u.a. am Schauspiel
            Düsseldorf, im Pavillon Hannover und am Hessischen Landestheater
            Marburg gezeigt. 2023 erhielt Inana das SchreibZeit-Stipendium der
            Stiftung Niedersachsen.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Heike Geißler",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Geißler_Heike_176_HR_HeikeSteinweg_SV(2).jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Heike"} /> <PrettyName name={"Geißler"} /> , 1977
            in Riesa geboren, ist Autorin, Übersetzerin und Mitherausgeberin der
            Heftreihe Lücken kann man lesen. Zuletzt erschienen der
            Reportage-Roman Saisonarbeit (Spector Books, 2014), das Fragenheft
            und Hörspiel Fragen für alle sowie das gemeinschaftliche
            Literaturprojekt Check your habitus, kuratiert von Daniela Dröscher.
            Mit der Schauspielerin Charlotte Puder arbeitet sie als Kollektiv
            George Bele. Heike Geißler wurde mit zahlreichen Stipendien und
            Preisen ausgezeichnet und lebt heute in Leipzig.
            <br></br>
            <br></br>
            Foto: © Heike Steinweg/Suhrkamp Verlag.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Hakan Tezkan",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Tezkan_Hakan_Foto_ Dinçer GüçyeterJPG.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Hakan"} /> <PrettyName name={"Tezkan"} /> ,
            geboren 1989, Studium am Deutschen Literaturinstitut Leipzig.
            Finalist beim 25. open mike, Veröffentlichungen u.a. in Neue
            Rundschau und etcetera. Den Kern schluckt man nicht ist sein Debüt.
            <br></br>
            <br></br>Foto: Dinçer Güçyeter
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Giorgio Ferretti",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Ferretti_Giorgio_credits_David_Tiefenthaler.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Giorgio"} /> <PrettyName name={"Ferretti"} /> ist
            1990 in Lecco, Italien, geboren und wohnt seit 2014 in Deutschland.
            Seit 2019 studiert er am Deutschen Literaturinstitut in Leipzig. Er
            wirkt in dem Verlagskollektiv hochroth Leipzig mit und ist in der
            Redaktion der Literaturzeitschrift Edit. Prosa, Lyrik, Essays und
            Dramatisches von ihm wurden in verschiedenen Zeitschriften
            veröffentlicht, u.A. in BELLA Triste, PS Politisch Schreiben, Das
            Narr. 2022 hat er den exil-Dramatiker:innenpreis gewonnen.
            <br></br>
            <br></br>Bildcredits: David Tiefenthaler
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Funkin Further",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/IMAGE 2023-04-26 09_32_51.jpg"
          ></img>
          <p className="font-size-1">
            Das Uni-Band-Projekt <PrettyName name={"Funkin"} />{" "}
            <PrettyName name={"Further"} /> steht seit 2011 in wechselnder
            Besetzung für stets hochkarätigen Funk, Soul und Pop und heizt mit
            seinem energiegeladenen Programm ordentlich ein: frischer Groove,
            der Laune macht. Unter der Leitung von Sigi Dresen begeistern die
            Spielbegeisterten immer wieder mit musikalischen Perlen aus
            verschiedenen Jahrzehnten. Tanzwütige (und alle anderen, die Lust
            auf gute Laune haben) sind herzlich willkommen!
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Fremd Zuhause",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/fremdzuhause_sequence1.jpg"
          ></img>
          <p className="font-size-1">
            Der Spaziergang hat in der Pandemie an Aktualität gewonnen: Viele
            Menschen haben eine Offenheit und Neugier entwickelt, die
            unmittelbare Umgebung zu erkunden. Der Audiowalk «Fremd Zuhause»
            fügt dieser Entdeckungsreise weitere Dimensionen hinzu. Der
            Spaziergangsgenerator kreiert jedes Mal eine neue Route. Das Handy
            als Orientierungshilfe ist auch für Migrant:innen überlebenswichtig,
            wobei das Verirren zum Fremdsein dazugehört. <br></br>
            <br></br> Die Erzählstimme schärft unsere Wahrnehmung: Wussten Sie,
            dass Sitzbänke erst mit dem Tourismus im 19. Jahrhundert aufkamen?
            Haben Sie sich schon einmal überlegt, dass das Spielgeräte eine
            Vorstellung vom «richtigen Spiel» beinhalten? Inwiefern demonstriert
            die Architektur von Gemeindegebäuden und Gotteshäusern Macht? Und
            wie lange noch wird aus jedem Brunnen Wasser fliessen? <br></br>
            <br></br> Der Schriftsteller Usama Al Shahmani erzählt auf dem Weg
            zur Sitzbank, wie befremdet er anfangs war ob der Schweizer
            Leidenschaft für das Wandern – und wie er selber zum passionierten
            Wanderer wurde. Auf dem Spielplatz erinnert sich Samira El-Maawi,
            welche mulmigen Gefühle das Spiel «Wer hat Angst vor dem schwarzen
            Mann» bei ihr als Kind auslöste. Beim Brunnen berichtet Asa S.
            Hendry von subtilen Mechanismen des Ausschlusses in einem Bündner
            Bergdorf. Bei der Kirche erzählt Angelika Overath von Kathedralen
            und Moscheen, die ihr auf Reisen Schutz bieten. Und Melinda Nadj
            Abonji beschreibt, wie wichtig das Fremdsein für ihre Literatur ist:
            Eine Einladung, die Sprache von der Last des Vertrauten zu befreien.{" "}
            <br></br>
            <br></br>Konzept und Handlungsanweisungen:{" "}
            <a href="https://karinbucher.ch/">Karin&nbsp;Bucher</a>
            <br></br>Konzept und Text:{" "}
            <a href="https://christinacaprez.ch/About">Christian&nbsp;Caprez</a>
            <br></br> Ton und Musik:{" "}
            <a href="https://klanggestalter.ch/">Martin&nbsp;Bezzola</a>
            <br></br>Grafik und Programmierung:{" "}
            <a href="https://stillhartkonzept.ch/">Jane&nbsp;Schindler</a>
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Franziska Gänsler",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Franziska Gänsler Foto c Linda Rosa Saal.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Franziska"} /> <PrettyName name={"Gänsler"} />{" "}
            hat in Berlin, Wien und Augsburg Kunst und Anglistik studiert. 2020
            stand sie auf der Shortlist des Blogbuster Preises und war
            Finalistin des Open Mike. *Ewig Sommer* ist ihr Debütroman, er wurde
            für mehrere Preise nominiert und erscheint im Herbst 2023 auf
            Französisch. <br></br>
            <br></br>Foto: Linda Rosa Saal
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Elona Beqiraj",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Beqiraj_Elona_Foto_ Alin Daghestani.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Elona"} /> <PrettyName name={"Beqiraj"} /> lebt
            und arbeitet in Berlin. Sie gibt Workshops zur Kontinuität Rechter
            Gewalt in Deutschland und hat diesbezüglich im Maxim Gorki Theater
            unter anderem die Kunstprojekte „Weil wir nicht vergessen“ und
            „Solange wir erinnern“ geleitet, die sich einer würdevollen
            Erinnerungskultur widmen. In ihrem Gedichtband „und wir kamen jeden
            sommer“, der 2019 im Resonar Verlag erschienen ist, beschäftigt sie
            sich der Frage nach Zu- und Unzugehörigkeit in Deutschland. Zudem
            gibt sie Schreibworkshop, die sich ebenfalls diesen Themen widmen.
            <br></br>
            <br></br>
            Foto : Alin Daghestani
          </p>
        </div>
      </>
    ),
  },

  {
    title: "dyke dogs (Eva Tepest & Lynn Takeo Musiol)",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Dyke_Dogs_Foto_ Yana Kaziulia.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Lynn"} /> <PrettyName name={"Takeo"} />{" "}
            <PrettyName name={"Musiol"} />
            arbeitet als Autor:in, Regisseur:in und Performer:in mit dem Fokus
            auf Klima, Klasse und Queerness. Lynn Takeo ist Mitgründer:in des
            Theater-Kollektivs les dramaturx, das aktuell am Theater Magdeburg
            die Inszenierung »Meister Röckle« auf die Bühne bringt.
            <br></br>
            <br></br>
            <PrettyName name={"Eva"} /> <PrettyName name={"Tepest"} /> ist
            Autor:in und Journalist:in. Evas Schreibpraxis konzentriert sich auf
            autofiktionale und essayistische Textformen. Wie können
            marginalisiert Autor:innen ihre Erfahrungen erzählen? Kann ein Text
            submissive sein? Evas Essayband »Power Bottom« erschien im März im
            MÄRZ Verlag. Dey schreibt eine Sex-Kolumne für das Missy Magazine.
            <br></br>
            <br></br>
            Gemeinsam betreiben Musiol/Tepest das Performance- und
            Schreibkollektiv DYKE DOGS, mit dem sie u. a. die gleichnamige
            lesbisch-queere Kulturpartie in der Berliner Schaubühne
            organisieren. Mit ihrem gemeinsamen Schreiben waren Musiol & Tepest
            Finalist:innen des Open Mikes 2020. Ihre Maxime: Dyke is a riot.
            Dyke ist tragisch. Dyke ist immer unzureichend.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Duygu Ağal",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/12-22_DuyguAgal_Handprints_003-Wiederhergestellt Kopie.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Duygu"} /> <PrettyName name={"Ağal"} />
            ist Autor:in. Der:die in Hamburg aufgewachsene:r lebt in Berlin und
            hat im April vergangenen Jahres seinen:ihren Debütroman Yeni
            Yeşerenler mit dem Korbinian Verlag publiziert. Ağal hat mit Yeni
            Yeşerenler eine fragmentarische Coming-of-Age-Geschichte
            geschrieben, in der goldene Birkenstocks und anstrengende
            Alman-Therapeutinnen genauso ihren Platz haben, wie die abweisenden
            Insignien einer Welt, die nicht für einen gemacht scheint – aber
            auch und gerade deshalb: queer-lesbische Liebe, ein neues Leben,
            Frauenfussball, Gewalt, Verzweiflung, Emanzipation,
            Selbstbewusstsein, Freund:innenschaft und so vieles mehr.
            <br></br>
            <br></br> Foto: Eden Jetschmann<br></br>
            (IG: @jetphoto)
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Damon Taleghani",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Damon Taleghani.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Damon"} /> <PrettyName name={"Taleghani"} />
            ist Autor, Musiker und Künstler in Berlin. Für das Buchprojekt
            Macetti über das Exil der leninistischen Tudeh-Partei des Iran in
            der DDR erhielt er 2021 das Recherchestipendium Literatur des
            Berliner Senats und war 2022 Aufenthaltsstipendiat der Akademie
            Schloss Solitude in Stuttgart.Neben der Arbeit an Macetti schreibt
            er unter anderem Punktexte.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Cana Bilir-Meier",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Bilir-Meier_Cana_Foto_ Aleyna Osmanoğlu.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Cana"} />{" "}
            <PrettyName name={"Bilir-"} appendWhiteSpace={false} />
            <PrettyName name={"Meier"} />
            studierte an der Akademie der bildenden Künste Wien sowie an der
            Sabancı-Universität in Istanbul. 2021 war sie Gastprofessorin für
            Kunstpädagogik an der Akademie der bildenden Künste in München. Sie
            arbeitet als Filmemacherin, Künstlerin und Kunstpädagogin in München
            und Wien. 2018 hat sie die Initiative zum Gedenken an Semra Ertan
            mitbegründet und 2020 den Gedichtband „Semra Ertan. Mein Name ist
            Ausländer / Benim Adım Yabancı“ mit herausgegeben. <br></br>
            <br></br>Foto: Aleyna Osmanoğlu
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Behzad Karim Khani",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/DSCF3946.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Behzad"} /> <PrettyName name={"Karim"} />{" "}
            <PrettyName name={"Khani"} />, 1977 geboren in Teheran exilierte
            1986 nach Deutschland, studierte Kunstgeschichte und
            Medienwissenschaft an der Ruhruniversität Bochum. Er lebt in
            Berlin-Kreuzberg und arbeitet als Autor und freier Journalist. 2022
            war er für den Ingeborg Bachmann Preis nominiert. Sein
            preisgekrönter Roman 'Hund, Wolf, Schakal' erschien bei Hanser
            Berlin. <br></br>
            <br></br>VALERIE BENNER (Foto)
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Tanasgol Sabbagh",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="images\SaL_Sabbagh_Fotograf © Sergen Yener.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Tanasgol"} /> <PrettyName name={"Sabbagh"} />
            ist Dichterin und Spoken Word Autorin. Sie ist in Amol, Iran,
            geboren und in Hessen aufgewachsen. Ihre literarischen Arbeiten
            präsentiert sie in Performances, Audiostücken, Videoinstallationen
            und musikalischen Kollaborationen. Sie ist Mitbegründerin des
            Künstler:innenkollektivs parallelgesellschaft sowie der
            gleichnamigen Veranstaltungsreihe, die politische Kunst abseits der
            deutschen Leitkultur verhandelt. Zusammen mit der Lyrikerin Josefine
            Berkholz ist sie Gründerin und Redakteurin des auditiven
            Literaturmagazins Stoff aus Luft, das als Podcast erscheint: Ein
            Format das gesprochene und klangbasierte Literatur in den
            Vordergrund stellt. Tanasgol lebt in Berlin.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "அவ்ரீனா பிரபலா-ஜாஸ்லின் / avrina prabala-joslin",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%", width: "100%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Prabala-Joslin_Avrina_Foto_ Michelle Gutiérrez.jpeg"
          ></img>
          <p className="font-size-1">
            அவ்ரீனா பிரபலா- ஜாஸ்லின் / <PrettyName name={"Avrina"} />
            <PrettyName name={"Prabala-"} appendWhiteSpace={false} />{" "}
            <PrettyName name={"Joslin"} />
            (1992, Tamil Nadu) schreibt/performt Prosa und Lyrik über Orte,
            Wesen und Zeiten. avrinas Schreiben ist besessen von Erinnerungen,
            oft aus der Kindheit, die sich an- und wieder entziehen; wie Ebbe
            und Flut, die für avrinas Sehnsucht nach dem Meer stehen. avrinas
            tamilisches Ich, das von avrinas Englisch frustriert ist, schreibt
            nun eigenständig als Chella Thambi. avrina gewann den Short Fiction
            / University of Essex International Short Story Prize 2021, den die
            Schriftstellerin Irenosen Okojie vergeben hat, stand auch auf der
            Shortlist für den Indiana Review Fiction Prize 2021, den Radical Art
            Review Contest 2021, den Berlin Writing Prize 2019 sowie der
            Longlist für den Desperate Literature Short Fiction Prize 2021.
            avrina hat unter anderem beim Mathrubhumi International Festival of
            Letters Kerala 2023, Poetry Meets, dem Literaturhaus Berlin, dem
            LCB, dem poesie festival Berlin und dem Performing Arts Festival
            Berlin performt. Als Absolvent:in des MA Writing Programms an der
            University of Warwick schreibt avrina derzeit eine Antidissertation
            über Meena Kandasamys Erinnerungspolitik.<br></br>
            <br></br> <a href="http://www.avrinajos.net">www.avrinajos.net</a>{" "}
            <br></br>
            <br></br>Foto: Michelle Gutiérrez
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Armeghan Taheri",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Armeghan photo by Bethany Burgoyne.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Armeghan"} />
            <PrettyName name={"Taheri"} /> (aka Robin Hoe) ist Schriftstellerin,
            Künstlerin und Herausgeberin. Sie ist Gründerin von Afghan Punk -
            einem mehrsprachigen Community-Magazin, in dem Befreiungskämpfe
            durch Storytelling kreativ miteinander verbunden werden. In ihrer
            künstlerischen Praxis arbeitet sie an der Sprengung von strukturell
            auferlegten Grenzen von Sprache, Kunst und Politik. Sie lebt und
            arbeitet in Berlin. <br></br>
            <br></br>Foto: Bethany Burgoyne
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Anna Kim",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Anna Kim_c_E. van Lanen.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Anna"} />
            <PrettyName name={"Kim"} />, geboren 1977 in Daejeon, Südkorea.
            Studium der Philosophie und Theaterwissenschaft an der Universität
            Wien. Veröffentlichungen (Auswahl): „Die gefrorene Zeit“
            (Literaturverlag Droschl, 2008), „Invasionen des Privaten“
            (Literaturverlag Droschl 2011), „Anatomie einer Nacht“ (Suhrkamp
            Verlag, 2012), "Die große Heimkehr“ (Suhrkamp, 2017), „Geschichte
            eines Kindes“ (Suhrkamp, 2022). Ihr Roman „Geschichte eines Kindes“
            wurde für den Österreichischen und Deutschen Buchpreis nominiert.
            Für ihre Werke erhielt sie zahlreiche Stipendien und Auszeichnungen,
            u.a. den Literaturpreis der Europäischen Union und das
            Robert-Musil-Stipendium. <br></br>
            <br></br>Foto: E. van Lanen
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Anna Hetzer",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Hetzer_Anna.Fotograf_Björn Kuhligk.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Anna"} />
            <PrettyName name={"Hetzer"} />, geboren 1986, wuchs in einer
            deutsch-polnischen Familie in Berlin auf. Sie studierte Medizin,
            Philosophie und Literatur ebendort. Im Anschluss arbeitete sie in
            einer psychiatrischen Klinik als Ärztin. Ihr literarisches Schreiben
            umfasst Lyrik, Essays und Übersetzungen. Zuletzt erschienen die
            Bände _Pandoras Playbox_ im Verlagshaus Berlin, sowie _Schaum_ bei
            Sukultur. Sie ist Mitglied des Lyrikkollektivs G13 und beteiligt
            sich zudem an verschiedenen künstlerischen Kooperationen. Ihre
            Arbeiten wurden mehrfach ausgezeichnet.
            <br></br>
            <br></br>
            Fotograf: Björn Kuhligk
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Amir Gudarzi",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Amir_Gudarzi_Foto_@_Stefan_Sappert.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Amir "} />
            <PrettyName name={"Gudarzi"} />, geboren in Teheran, graduierte dort
            an der damals einzigen Theaterschule im Iran und schloss das Studium
            Szenisches Schreiben ab. Seit 2009 lebt Gudarzi in Wien. 2017 gewann
            er den exil-DramatikerInnenpreis, 2019 wurde er mit Burg der
            Assassinen zum Stückemarkt Berlin-Theatertreffen eingeladen. Seine
            Arbeiten waren zu dieser Zeit in Jerusalem, London, Wien und
            mehreren deutschen Städten zu sehen. 2021 wurde Gudarzi mit dem
            Förderungspreis für Literatur der Stadt Wien ausgezeichnet, 2022
            gewann sein Stück Wonderwomb den Kleist-Förderpreis für junge
            Dramatik. Es erhielt außerdem eine „spezielle Erwähnung“ durch die
            Jury der Autor:innentheatertage 2022. Im selben Jahr wurde Gudarzi
            der Anerkennungspreis der Sparte Literatur des Landes
            Niederösterreich verliehen. 2023 erscheint sein Debütroman bei dtv.
            <br></br>
            <br></br>
            Fotograf: Stefan Sappert
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Katharina Angus",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/KatharinaAngus.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Katharina"} />
            <PrettyName name={"Angus"} /> studierte Drehbuchschreiben,
            Literaturwissenschaft und Osteuropawissenschaften mit Aufenthalten
            in Krakau und Prag. Sie veröffentlichte unter anderem in den
            Literaturzeitschriften Mosaik und BELLA triste. Im Jahr 2021 war sie
            Stipendiatin des Klagenfurter Literaturkurses und Teilnehmerin beim
            Stuttgarter Lit.Fest.
            <br />
            Vom Hessischen Literaturrat e.V. und dem Prager Literaturhaus
            deutschsprachiger Autoren erhielt sie im November 2022 ein
            Aufenthaltsstipendium in Prag, um an ihrem Debütroman zu arbeiten.
            <br />
            Sie lebt als freie Journalistin in Berlin.
            <br /> <br />© privat
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Philipp Laue",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/PhilippLaue.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Philipp"} /> <PrettyName name={"Laue"} />{" "}
            (er/ihn), 1996 in Halle/Saale geboren, aufgewachsen im
            Mitteldeutschen Chemiedreieck, studierte Psychologie in Jena. Nach
            Beschäftigungen als Universitätsdozent und in der Jugendhilfe,
            arbeitet er mittlerweile als wissenschaftlicher Mitarbeiter und lebt
            in Leipzig. In seinen Texten verhandelt er u. a. Leben in dörflichen
            (Un)Strukturen, familiäre Zerwürfnisse, Beziehungslosigkeit in
            Beziehungen. Seine Gedichte und Prosa werden seit 2021 in
            verschiedenen Independent-Magazinen (HotTopic!, FLUT Magazin, DRAMA,
            apostrophe u. a.) gedruckt.
            <br />
            twitter/instagram: @imbluescreen.
            <br /> <br />
            Bildrechte: Jonas Erler
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Vici Dewinski",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/ViciDewinski.JPG"
          ></img>
          <p className="font-size-1">
            <PrettyName name="Vici" /> <PrettyName name={"Dewsinski"} /> wurde
            1998 in Chemnitz geboren. Sie studiert Bühnen- und Kostümbild an der
            Hochschule für Bildende Künste in Dresden und ist Stipendiatin der
            Studienstiftung des deutschen Volkes. Seit 2021 arbeitet Vici als
            Installationskünstlerin, Regisseurin und Autorin. Dabei erschafft
            sie performative Installationen, die an der Schnittstelle zwischen
            Bildender Kunst, Theater und Animation operieren. Ihre Arbeit
            zeichnet sich durch gewaltige Atmosphären, grotesken Humor und bis
            ins Detail abgestimmte Räume aus. Ihre Texte entführen uns in eine
            Welt voller unausgesprochener Gedanken und Emotionen.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Nima Schaper",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/NimaSchaper.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Nima"} />{" "}
            <PrettyName name={"Schaper"} appendWhiteSpace={false} />, geboren
            und groß geworden in Köln. Nach dem Abitur an Kölner Gesamtschule
            ein Jahr in Odessa, Ukraine gelebt. Studiert seit Wintersemester
            21/22 Kulturwissenschaften und Ästhetische Praxis an der Universität
            Hildesheim. Außerdem als DJ, Music Producer und Sound/Video-Künstler
            tätig.
          </p>
        </div>
      </>
    ),
  },
  {
    title: "Agon Beqiraj",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Agon Beqiraj.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Agon"} /> <PrettyName name={"Beqiraj"} />
            ist ein aufstrebender Musiker und Produzent, der früh in seinem
            Leben seine Leidenschaft für die Musik entdeckte.
          </p>
          <p className="font-size-1">
            Sein Sound bewegt sich zwischen den Richtungen Pop und Rap, die er
            ineinander verschmelzen lässt und durch diese Mischung verschiedener
            Genres seinen eigenen unverwechselbaren Sound entwickelt. Inspiriert
            von seinen Erfahrungen und Gefühlen möchte er Menschen emotional
            erreichen und ihnen auf verschiedenste Weisen helfen.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Mira Sidawi",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/mirasidawi.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Mira"} /> <PrettyName name={"Sidawi"} />
            is a Palestinian writer actress and director and activist. She
            graduated from the Lebanese university with a Theatre diploma. In
            2015 she created her first short film. The name of the film is Four
            Wheels Camp. She took a role in a different plays such as: Kafas
            directed by Lina Abyad, Systematical directed by Badih Abu
            Shakra.etc... She directed several performances in different schools
            and universities. Now she is working as a trainer in DW media
            workshops in Shatila camp for investigation form and story telling.
            She also created different portraits about characters lived in the
            camps. Now, she is preparing for a web series that holds the real
            black comedy found in the camp. She took a role in different feature
            and short films as: Permission, instead of a homeland, Mon soufflé.
            Her last theatre play name "AYOUBE", she played the role of
            Palestinian woman that faces her own struggles and obstacles. She
            participated in writing the theatre script as well. Mira is
            preparing now for her first feature film that happens in the between
            Bourj Barajani and Shatila. Mira also, is working on her first novel
            that describes the life in the camp which more closely to be living
            in a maze.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Deborah Lara Schaefer",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/Portrait©SharonRomySchaefer.jpeg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Deborah"} /> <PrettyName name={"Lara"} />{" "}
            <PrettyName name={"Schaefer"} />
            ist Autorin und Künstlerin, geboren in Schlieren, CH. Sie schreibt
            in mehreren Sprachen für die Bühne und tritt auch als Sängerin auf.
            Sie ist Absolventin des Schweizerischen Literaturinstituts mit
            Gastaufenthalt im 15. Jahrgang «Szenisches Schreiben» an der UdK
            Berlin. Im Anschluss studierte sie Schauspielregie an der Zürcher
            Hochschule der Künste. 2023 erschien ihr Erzählband «LIBIDO LUCID»
            bei Label Rapace.
          </p>
        </div>
      </>
    ),
  },

  {
    title: "Lara Hajj Sleiman",
    contentJSX: (
      <>
        <div style={{ padding: " 1.5rem", lineHeight: "200%" }}>
          <img
            alt=""
            style={{ width: "100%", borderRadius: "2.2rem" }}
            src="/images/IMG_2227.jpg"
          ></img>
          <p className="font-size-1">
            <PrettyName name={"Lara"} /> <PrettyName name={"Hajj"} />{" "}
            <PrettyName name={"Sleiman"} />, geboren 1993 in Oberhausen.
            Studierte Literarisches Schreiben am Schweizerischen
            Literaturinstitut. Interessiert sich für Marxismus und Kritische
            Theorie und schreibt zur Zeit Science-Fiction zur Klimakrise und der
            Möglichkeit einer nach-kapitalistischen Gesellschaft.
          </p>
        </div>
      </>
    ),
  },
];
