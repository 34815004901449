import React from "react"

export const TextOnTop = "Festivalarbeit funktioniert nicht ohne die vielen Menschen, die die unterschiedlichen Aufgaben übernehmen und meist im Hintergrund stehen. Für das PROSANOVA findet sich alle drei Jahre ein komplett neues Team zusammen, dass sich diesem annimmt. Auch wenn sich hier nur das Team der diesjährigen Ausgabe findet, sind wir dankbar für alle die vor uns waren und uns den Weg zum PROSANOVA 2023 geebnet haben."


export const Leitung = [
    {
        "imgSrc": "images/Leni.png",
        "imgTitle": "Foto: Anja König",
        "imgObjectPosition": "50% 15%",
        "sectionId": "leni-bio",
        "altText": "Leni",
        "name1": "Leni von der",
        "name2": "Waydbrink",
        "sectionContent": <>
            , geboren in Berlin, studiert seit 2020 Literarisches Schreiben, Theater und Medien an der Universität Hildesheim. Sie arbeitet und schreibt fürs Theater. Veröffentlichungen erfolgten u.A. beim <i>4+1 festival für junge autorInnen</i> am Schauspiel Leipzig. Im Mai 2023 wird ihr Text <i>ZU KÜSTEN oder: Wir standen uns die Beine in den Arsch</i> im Rahmen des Nachwuchswettbewerbs <i>Die gestohlene Zukunft</i> am Theater an der Drachengasse in Wien gezeigt. Sie war Mitherausgeberin der Zeitschrift <i>BELLA triste</i>. Beim <i>PROSANOVA 2023</i> ist sie für die Ressorts Produktion und Infrastruktur verantwortlich.
        </>
    },
    {
        "imgSrc": "images/Melek.png",
        "imgTitle": "Foto: Anja König",
        "imgObjectPosition": "50% 10%",
        "sectionId": "melek-bio",
        "altText": "Melek",
        "name1": "Melek",
        "name2": "Halici",
        "sectionContent": <>
            {" "}studiert Literarisches Schreiben, Philosophie und Theater in Hildesheim. Schreibt Texte. Zuletzt eingeladen zum Festival <i>4+1 ein treffen junger autorInnen</i> in Leipzig. Sie arbeitet in der Geschäftsstelle des <i>wortbau e.V.</i>, an der Schnittstelle von Literatur und der Arbeit mit Kindern und Jugendlichen. Sie war Mitherausgeberin der Literaturzeitschrift <i>BELLA triste</i>. Sie ist Teil der Künstlerischen Leitung beim <i>PROSANOVA 2023</i> und zuständig für die Ressorts Kommunikation, Presse und Vermittlung.
        </>
    },
    {
        "imgSrc": "images/Hiyam.png",
        "imgTitle": "Foto: Anja König",
        "imgObjectPosition": "50% 15%",
        "sectionId": "hiyam-bio",
        "altText": "Hiyam",
        "name1": "Hiyam",
        "name2": "Biary",
        "sectionContent": <>
            {" "}ist in Berlin-Kreuzberg geboren und aufgewachsen. Sie arbeitet als Performerin, Referentin und ist in verschiedenen Community-Kontexten unterwegs. In ihrer Kunst und Arbeit ist ihr ein interdisziplinärer Vermittlungsansatz wichtig. Diese Schwerpunkte verbindet sie unter anderem in der Festivalarbeit und vor allem als Performerin auf deutschlandweiten Theaterbühnen, zuletzt als Leitung im Projekt <i>Weil wir nicht vergessen</i> vom <i>Maxim Gorki Theater</i>, in dem sie sich der Frage nach politischem Gedenken und würdevollem Erinnern im Kontext von rechten Kontinuitäten in Deutschland widmete. Beim <i>PROSANOVA 2023</i> ist sie als Teil der Künstlerischen Leitung  zuständig für die Ressorts Presse, Social Media und Infrastruktur.
        </>
    },
    {
        "imgSrc": "images/Lucie.png",
        "imgTitle": "Foto: Anja König",
        "imgObjectPosition": "50% 20%",
        "sectionId": "lucie-bio",
        "altText": "Lucie",
        "name1": "Lucie",
        "name2": "Frahm",
        "sectionContent": <>
            , geboren in Berlin, nach dem Abitur absolvierte sie ein FSJ bei der Kunstvermittlung der Staatlichen Museen zu Berlin. Studiert in Hildesheim Literatur und Medien, wirkte dort u.A. in einem Verein für Kinokultur mit. War Mitherausgeberin der Anthologie <i>Landpartie 2021</i>, seit 2022 ist sie Mitherausgeberin der <i>BELLA triste</i>. Schreibt auch selbst. Beim <i>PROSANOVA 2023</i> ist sie zuständig für die Ressorts Finanzen und Produktion.
        </>
    },
    {
        "imgSrc": "images/Lina.png",
        "imgTitle": "Foto: Anja König",
        "imgObjectPosition": "50% 15%",
        "sectionId": "lina-bio",
        "altText": "Lina",
        "name1": "Lina",
        "name2": "Briks",
        "sectionContent": <>
            {" "}ist in Hamburg geboren und aufgewachsen und studierte zunächst Klassische Philologie und Romanistik in Hamburg und Salerno, anschließend European Studies in Amsterdam und Literary Translation in Norwich. Lina arbeitet als freie Übersetzer*in und Lektor*in unter anderem für New Writing, Eggbox Publishing und das National Centre for Writing. Seit 2022 ist Lina Mitherausgeber*in der Literaturzeitschrift  <i>BELLA triste</i>. Beim <i>PROSANOVA 2023</i> ist Lina für die Bereiche Finanzen und Presse zuständig.
        </>
    },
    {
        "imgSrc": "images/Clara.png",
        "imgTitle": "Foto: Anja König",
        "imgObjectPosition": "50% 15%",
        "sectionId": "clara-bio",
        "altText": "Clara",
        "name1": "Clara",
        "name2": "Dönicke",
        "sectionContent": <>
            {" "}ist in Hamburg geboren und studiert Literarisches Schreiben, Musik und Philosophie in Hildesheim. Ihre Prosatexte sind bisher in Anthologien erschienen, 2021 war sie in der Redaktion des vierteiligen Podcasts <i>Die Toten</i>. Beim <i>PROSANOVA 2023</i> ist sie als Teil der Künstlerischen Leitung für Finanzen und Kommunikation zuständig.
        </>
    }
]

export const Praktikant_innen = [
    {
        "imgSrc": "images/anna-lena-maatz.png",
        "imgObjectPosition": "50% 45%",
        "sectionId": "anna-lena-bio",
        "altText": "Anna-Lena Maatz",
        "name1": "Anna-Lena",
        "name2": "Maatz",
        "sectionContent": <>
            , aufgewachsen im Allgäu, studiert seit 2020 Vergleichende
            Literaturwissenschaft an der Universität Augsburg. Neben dem Studium Teil
            des Online-Magazins "Schauinsblau" und Referentin für Kultur des AStA an
            der Universität Augsburg. Bei PROSANOVA 23 ist sie Praktikantin im Bereich
            Presse und Social Media.
        </>
    },
    {
        "imgSrc": "images/bela-neumann.png",
        "imgObjectPosition": "50% 35%",
        "sectionId": "bela-bio",
        "altText": "Bela Neumann",
        "name1": "Béla",
        "name2": "Neumann",
        "sectionContent": <>
            , geboren in Berlin, war 5 Jahre Teil der Statisterie der Deutschen Oper  Berlin und hat an zwei interdisziplinären Projekten der Bühnenkunstschule ACADEMY  mitgewirkt. Seit 2022 studiert er Literarisches Schreiben und Kulturjournalismus in  Hildesheim und ist Bassist in einer Indie-Band. Für das PROSANOVA 2023 ist er Praktikant  im Bereich Produktion.
        </>
    },
    {
        "imgSrc": "images/dilara-buzoglu.png",
        "imgObjectPosition": "50% 35%",
        "sectionId": "dilara-bio",
        "altText": "Dilara Buzoğlu",
        "name1": "Dilara",
        "name2": "Buzoğlu",
        "sectionContent": <>
            , geboren 1999 in Niedersachsen. Studierte an der Freien Universität Berlin sowie seit 2019 an der Universität Wien im Fach der Theater- Film- und Medienwissenschaften. Durch ihr Studium beschäftigt sie sich viel mit herrschaftskritischen Fragen und forscht gerade im Rahmen ihrer Bachelorarbeit zu Feminismen in der SWANA-Region.
        </>
    },
    {
        "imgSrc": "images/leon-ahlhorn.png",
        "imgObjectPosition": "50% 40%",
        "sectionId": "leon-bio",
        "altText": "Leon Ahlborn",
        "name1": "Leon",
        "name2": "Ahlborn",
        "sectionContent": <>
            , aufgewachsen in Göttingen. Studierte zunächst Humanmedizin
            und später im Bachelor Philosophie und Germanistik an der Universität
            Halle. Mittlerweile Masterstudium der Philosophie an der Universität Jena.

        </>
    },
    {
        "imgSrc": "images/linda-rabe.png",
        "imgObjectPosition": "50% 45%",
        "sectionId": "linda-bio",
        "altText": "Linda Rabe",
        "name1": "Linda",
        "name2": "Rabe",
        "sectionContent": <>
            , aufgewachsen in Mönchengladbach, studiert derzeit Philosophie-Künste-Medien an der Universität Hildesheim. Bei PROSANOVA 2023 ist sie Praktikantin im Bereich Infrastruktur.
        </>
    },
    {
        "imgSrc": "images/lynn-stechert.png",
        "imgObjectPosition": "50% 25%",
        "sectionId": "lynnve-bio",
        "altText": "Lynn Stechert",
        "name1": "Lynn Ve Sophia",
        "name2": "Stechert",
        "sectionContent": <>
            , geboren 1998 in Braunschweig, studiert derzeit Kunstwissenschaften an der Universität Bremen. Im Wintersemester 2022/2023 studierte sie Contemporary Arts Practice am Y Institut für Transdisziplinarität der Hochschule der Künste Bern, Schweiz.
        </>
    },
    {
        "imgSrc": "images/marlene-horn.png",
        "imgObjectPosition": "50% 60%",
        "sectionId": "marlene-h-bio",
        "altText": "Marlene Horn",
        "name1": "Marlene",
        "name2": "Horn",
        "sectionContent": <>
            {" "}kommt aus Bonn und hat Philosophie, Kunst und Gesellschaftswissenschaften studiert. Das Studium der Kulturvermittlung mit Schwerpunkt Literatur hat sie nach Hildesheim gezogen. Neben dem Studium begleitet sie Workshops zur Selbsterfahrung und Berufsorientierung oder beschäftigt sich mit Lyrik und Illustration.
        </>
    },
    {
        "imgSrc": "images/marlene-regenfuß.png",
        "imgObjectPosition": "50% 27%",
        "sectionId": "marlene-r-bio",
        "altText": "Marlene Regenfuß",
        "name1": "Marlene",
        "name2": "Regenfuß",
        "sectionContent": <>
            {" "}ist in Göttingen aufgewachsen. Nach Sprüngen durch die süddeutsche  Unilandschaft und Praktika an kleinen Theatern ist sie nach Hildesheim gezogen. Hier studiert sie seit ein paar Jahren Kulturwissenschaften und ästhetische Praxis und erweitert ihr kulturelles Kapital. Sie schreibt Kurzprosa und Lyrik, spielt mit literarischen Ideen und dramatischen Situationen.
        </>
    },
    {
        "imgSrc": "images/nima-schaper.png",
        "imgObjectPosition": "50% 35%",
        "sectionId": "nima-bio",
        "altText": "Nima Schaper",
        "name1": "Nima",
        "name2": "Schaper",
        "sectionContent": <>
            , geboren und groß geworden in Köln. Nach dem Abitur an Kölner Gesamtschule ein Jahr in Odessa, Ukraine gelebt. Studiert seit Wintersemester 21/22 Kulturwissenschaften und Ästhetische Praxis an der Universität Hildesheim. Außerdem als DJ, Music Producer und Sound/Video-Künstler tätig.
        </>
    },
    {
        "imgSrc": "images/roya-schaper.png",
        "imgObjectPosition": "50% 85%",
        "sectionId": "roya-bio",
        "altText": "Roya Schaper",
        "name1": "Roya",
        "name2": "Schaper",
        "sectionContent": <>
            {" "}ist 19 Jahre alt und in Köln aufgewachsen. Momentan lebt sie zwischen Köln, London und Berlin. Roya liest viel, macht Musik, Filme und schreibt.
        </>
    },
    {
        "imgSrc": "images/tilman-busch.png",
        "imgObjectPosition": "50% 10%",
        "sectionId": "tilman-bio",
        "altText": "Tilman Busch",
        "name1": "Tilman",
        "name2": "Busch",
        "sectionContent": <>
            , geboren und aufgewachsen in Göttingen. Studierte in Halle (Saale) Soziologie und Wirtschaftswissenschaften. Seit 2021 Kreatives Schreiben und Kulturjournalismus an der Universität Hildesheim.
        </>
    },
    {
        "imgSrc": "images/RombachLeah.jpg",
        "imgObjectPosition": "50% 7%",
        "sectionId": "RombachLeah-bio",
        "altText": "Rombach Leah",
        "name1": "Leah",
        "name2": "Rombach",
        "sectionContent": <>
            {" "} studiert Literarisches Schreiben und Kulturjournalismus in Hildesheim. Davor lebte sie während eines kulturellen Freiwilligendienstes in Krakau und hospitierte bei verschiedenen Theaterproduktionen. Bei PROSANOVA 2023 ist sie Praktikantin im Bereich Produktion.
        </>
    },
    {
        "imgSrc": "images/SchelerJohanna.jpg",
        "imgObjectPosition": "50% 7%",
        "sectionId": "SchelerJohanna-bio",
        "altText": "Johanna Scheler",
        "name1": "Johanna",
        "name2": "Scheler",
        "sectionContent": <>
            , in Hamburg aufgewachsen, studiert seit 2021 „Szenische Künste“ in Hildesheim mit den Schwerpunkten in Film und Literatur. Neben dem Studium hat sie im letzten Jahr ihren ersten Kurzspielfilm frei produziert.
        </>
    },
    {
        "imgSrc": "images/WolterLuise.jpg",
        "imgObjectPosition": "50% 40%",
        "sectionId": "WolterLuise-bio",
        "altText": "Luise Wolter",
        "name1": "Luise",
        "name2": "Wolter",
        "sectionContent": <>
            {" "} ist in Lissabon und Erfurt aufgewachsen. Ihren Abschluss erlangte sie in der Visuellen Kommunikation an der Bauhaus-Universität Weimar mit den Schwerpunkten Grafikdesign und Fotografie. Zuzüglich der gestalterischen Ausbildung richtet sich ihr Interesse dem Bereich des Kulturmanagements. Derzeit studiert sie im Master Kulturvermittlung an der Stiftung Universität Hildesheim.
        </>
    }
]