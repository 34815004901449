
// 3D RELATED STUFF

export const GLB_FILE = "./PROSANOVA-WEBSEITE-MitHintergrundbild-expanded.glb"
export const BACKGROUND_FILE = "./PROSANOVA-Hintergrund.jpg"
export const SCALE = 1
export const LETTERS_OFFSET = 1.2 * SCALE
export const LETTERS_MASS = 1


// COLORS

export const BACKGROUNDCOLOR = "#e8e3dd"

export const COLORS = {
    lilalight: "#BFA9ED",
    brown: "#C18167",
    beige: "#C8BF9B",
    yellow: "#E3CC4E",
    pink: "#F97DD0",
    blue: "skyblue",
    orange: "#FF9B53",
    liladark: "#553c6d",
    background: "#e8e3dd"
}
